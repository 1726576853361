@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Poppins:wght@500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

/* ====== Header CSS ========== */

.header-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

/* ====== Input PlaceHolder CSS ========== */
#input::placeholder {
  font-size: 12px;
  font-weight: "400";
  line-height: 18px;
  text-align: left;
  color: #1c1c1c;
}

/* ====== FilteredProduct CSS ========== */
.grid-container {
  display: grid;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.mask {
  -webkit-mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 85%,
    rgba(0, 0, 0, 0) 100%
  );
  mask: linear-gradient(to right, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
}

/* Show warning message in portrait mode */
@media screen and (orientation: portrait) {
  .landscape-content {
    display: none;
  }
  .portrait-warning {
    display: block;
    text-align: center;
    margin-top: 50vh;
    transform: translateY(-50%);
    font-size: 1.5em;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* display: none; */
}

.custom-scrollbar:hover::-webkit-scrollbar {
  display: block;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
  background-clip: content-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ececec;
  border-radius: 8px;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.hide-scrollbar {
  scrollbar-width: none; /* For Firefox */
}

/* ==========Floating input css=========== */
.floating-input-group {
  position: relative;
}

.floating-input {
  padding: 10px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  outline: none;
  /* border: 1px solid #04AA6D; */
  background-color: #f8f8f8;
  border-radius: 9px;
}

.floating-placeholder {
  position: absolute;
  top: 10px;
  left: 8px;
  font-size: 14px;
  padding: 0px 5px;
  color: rgba(0, 0, 0, 0.28);
  transition: 0.3s;
  pointer-events: none;
}

.floating-input:focus + .floating-placeholder {
  top: -10px;
  color: rgba(0, 0, 0, 0.28);
  background-color: transparent;
}

.floating-fill {
  top: -10px;
  color: rgba(0, 0, 0, 0.28);
  background-color: transparent;
}

.product-name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*  remove the default blue background color of an input field that is auto-filled by the browser  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
